.box{
    padding: 1.25rem;
    margin: 0.625rem;
    border-radius: 0.625rem;
    display: flex;

}
.container-box{
    margin: 10px;
}
.frame{
    white-space: nowrap; /* This is required unless you put the helper span closely near the img */
}
.helper{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
img{
    vertical-align: middle;
}
.flex-box{
    padding: 0.625rem;
    flex: 1;
}
.img-box{
    width: 17.5rem;
}

.text-box{
    max-width: 41.875rem;

}

.accept-box{
    margin-bottom: 2.5rem;
    display: inline-grid;
    row-gap: 0.625rem;
}

svg {
    position: absolute;
    display: block;
}

.container-checkbox {
    display: inline-block;
    position: relative;
    width: 92px;
    height: 92px;

    #Layer_1 {
        width: 42px;
        left: 21px;
        top: 26px;
        stroke-dasharray: 30;
        stroke-dashoffset: 30;

        &.on {
            animation: check .5s;
            animation-delay: 1.2s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
    }

    #Layer_2 {
        width: 84px;
        stroke-dasharray: 258;
        stroke-dashoffset: 258;
        transform: rotate(270deg);

        &.on {
            animation: circle 1.4s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes circle {
    0% {
        stroke-dashoffset: 258;
        transform: rotate(90deg);
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes check {
    0% {
        stroke-dashoffset: 30;
    }
    100% {
        stroke-dashoffset: 0;
    }
}



.visa h2 {
    padding-top: 10px ;
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media (max-width: 767px) {
    .img-box {
        display:none;
    }
    .visa{
        padding-top: 0;
        flex: 1;
    }
    .visa h2 {
        padding-top: 0px;
        font-size: 18px;
        margin: 0;
        animation: fadeIn 4s;
        -webkit-animation: fadeIn 4s;
        -moz-animation: fadeIn 4s;
        -o-animation: fadeIn 4s;
        -ms-animation: fadeIn 4s;
    }


}

