.genericModelPortfolios {

    .portfolio {
        border: 1px solid @table-border-color;
        border-radius: (@panel-border-radius - 1);
        padding: 10px;
        font-size: 12px;
        margin-left: -12px;
        margin-right: -12px;
        margin-bottom: 10px;

        h3 {
            color: @brand-primary;
            font-size: 16px;
            margin-top: 0;
        }

        .riskvilja {
            color: #292929;
            font-size: 12px;
        }

        .textDescription {
            @media (min-width: @screen-lg-min) {
                min-height: 190px;
            }
            @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
                min-height: 140px;
            }
        }

        .graph {
            padding: 0;
            margin: 0;
        }

        .graph-riskAvkastning {
            .highcharts-container {
                @media (max-width: @screen-md-max) {
                    margin-top: 50px;
                }
            }
        }

    }

    div:first-of-type {
        div.portfolio {
            margin-left: -5px;
        }
    }

    div:last-of-type {
        div.portfolio {
            margin-right: -5px;
        }
    }

    .highcharts-container {
        margin: auto;
    }

}
