
.file-upload-drop-zone {
    width: 100%;
    border: 5px dashed @maxm-border-color;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 12px;

    &.drop-hover {
        background-color: darken(#fff, 10%);
        border-color: darken(@maxm-border-color, 10%);
    }
    &.drop-hover-err {
        background-color: @maxm-alert-red;
        border-color: @maxm-alert-text-color;
    }
}

.upload-icon {
    color : @maxm-border-color;
    font-size: 24px;
    position: relative;
    top: 6px;
    right: 2px;
}

.remove-upload {
    position: relative;
    float: right;
    cursor: pointer;
}

.remove-upload-pending {
    color : @maxm-border-color;
    position: relative;
    float: right;
    cursor: progress;
}

.file-upload-candidate {
    border-color:  @maxm-border-color;
    border-style: solid;
    border-width: thin;
    padding: 10px;
    border-radius: 5px;
}

