#bssimulation {

    input {
        border: 1px solid #cccccc;
        border-radius: 3px;
    }

    input.sm {
        width: 50px;
    }

    input.md {
        width: 75px;
        @media (max-width: @screen-sm-max) {
            width: 60px;
        }
    }

    input.lg {
        width: 150px;
    }

    .input-group-addon {
        width: 50px;
    }

    select {
        border: 1px solid #cccccc;
        border-radius: 3px;
    }

    .general {
        label.control-label {
            text-align: left;
            font-weight: normal;
            font-size: 13px;
            padding-right: 2px;
        }
    }

    @media (max-width: @screen-sm-max) {
        .general {
            .title {
                text-align: justify;
                padding-top: 10px;
            }
        }
    }

    table.simulation {
        border-collapse: inherit;

        td {
            font-size: 12px;
        }

        td.input, th.input {
            padding: 4px;
            background-color: #EDF4FC;
        }

        td.result, th.result {
            padding: 4px;
            span {
                padding: 4px;
                display: inline-block;
            }
            background-color: #E7FCDE;
        }

        input.input-group-addon {
            display: inline-table;
            background-color: white;
            float: left;
            margin-left: -4px;
        }

        .non-modifiable {
            padding: 4px;
            display: inline-block;
            vertical-align: top;
        }

        @media (max-width: @screen-md-max) {
            input.input-group-addon {
                display: inline-table;
                margin-top: 3px;
                margin-left: 0;
                border-left: 1px solid @table-border-color;
                width: 100px;
                .border-left-radius(3px);
            }
            .non-modifiable {
                padding: 8px;
                width: 100px;
                display: inline-block;
            }
        }

        > thead:first-of-type {
            > tr {
                border: 0 solid transparent;
            }
            > tr:first-child {
                border: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                th:first-child,
                td:first-child {
                    border: none;
                    border-top-left-radius: 0;
                }
                th:last-child,
                td:last-child {
                    border: none;
                    border-top-right-radius: 0;
                }
            }
        }

        > tbody {
            tr {
                td {
                    border-top: 4px white solid;
                }
                td:first-child {
                    border-left: 1px solid @table-border-color;
                }
                td:last-child {
                    border-right: 1px solid @table-border-color;
                }
            }

            tr.active-pension, tr.ps-border-radius {
                td, th {
                    border-top: none;
                }
            }

            > tr:first-child {
                td {
                    border-top: 1px solid @table-border-color;
                }
                td:first-child {
                    border-left: 1px solid @table-border-color;
                    border-top-left-radius: (@panel-border-radius - 1);
                }
                td:last-child {
                    border-right: 1px solid @table-border-color;
                    border-top-right-radius: (@panel-border-radius - 1);
                }
            }

            > tr:last-child {
                td {
                    border-bottom: 1px solid @table-border-color;
                }
                td:first-child,
                th:first-child {
                    border-bottom-left-radius: (@panel-border-radius - 1);
                }
                td:last-child,
                th:last-child {
                    border-bottom-right-radius: (@panel-border-radius - 1);
                }
            }
        }
    }

    .color-NATIONAL {
        color: #EBB04D
    }
    .color-OCCUPATIONAL {
        color: #4968C0
    }
    .color-PRIVATE {
        color: #77A7F6
    }
    .color-TEMPORARY {
        color: #A0DDFF
    }
    .color-UNKNOWN {
        color: #FFDB00
    }
    .dim {
        color: #999999;
        font-style: italic;
    }
}
