/* Component LESS */
@import "../branding/branding.less";
/* Add Component LESS Above */

.noHighlighting {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.disabledTab{
    pointer-events: none;
}

@media (min-width: 992px){
    .addPanelMargin .panel{
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (max-width: 991px){
    .addPanelMargin .panel{
        margin-left: 10px;
        margin-right: 10px;
    }
}


.disabled-link{
    pointer-events: none;
}

.match-dropdown-height{
    line-height: 28px;
}

.width-8rem {
    width: 5rem;
}

@media (max-width: 991px) {
    .table-condensed-md>tbody>tr>td,
    .table-condensed-md>tbody>tr>th,
    .table-condensed-md>tfoot>tr>td,
    .table-condensed-md>tfoot>tr>th,
    .table-condensed-md>thead>tr>td,
    .table-condensed-md>thead>tr>th {
        padding: 5px;
    }
}

@media (min-width: 991px) {
    .min-height-165 {
        min-height: 165px;
    }
}

.checkbox.identityCheck {
    margin-top: 15px;
}

.placeholder-styled::-webkit-input-placeholder {
    color: gray;
    font-style:italic;
    font-family: Helvetica, Arial, sans-serif; }  /* WebKit, Blink, Edge */
.placeholder-styled:-moz-placeholder {
    color: gray;
    font-style:italic;
    font-family: Helvetica, Arial, sans-serif;}  /* Mozilla Firefox 4 to 18 */
.placeholder-styled::-moz-placeholder {
    color: gray;
    font-style:italic;
    font-family: Helvetica, Arial, sans-serif; }  /* Mozilla Firefox 19+ */
.placeholder-styled:-ms-input-placeholder {
    color: gray;
    font-style:italic;
    font-family: Helvetica, Arial, sans-serif; }  /* Internet Explorer 10-11 */
.placeholder-styled:-ms-input-placeholder {
    color: gray;
    font-style:italic;
    font-family: Helvetica, Arial, sans-serif; }  /* Microsoft Edge */

#scrollable-dropdown-menu .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
}

#scrollable-dropdown-menu .dropdown-menu::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
#scrollable-dropdown-menu .dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
}

select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMTAgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiPiAgICAgICAgPHRpdGxlPlVudGl0bGVkPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+ICAgICAgICA8ZyBpZD0ic2VsZWN0LWFycm93cyIgc2tldGNoOnR5cGU9Ik1TTGF5ZXJHcm91cCIgZmlsbD0iIzAwMDAwMCI+ICAgICAgICAgICAgPHBhdGggZD0iTTUsMCBMMCw3IEwxMCw3IEw1LDAgTDUsMCBaIE01LDE4IEwxMCwxMSBMMCwxMSBMNSwxOCBMNSwxOCBaIiBpZD0iU2hhcGUiIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiPjwvcGF0aD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
    background-size: .5em;
    background-repeat: no-repeat;
    background-position: right .5em center;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 12px;
    padding-right: 12px;
}

select.form-control.inline {
    display: inline;
    width: 3.75rem;
}
