.portfolios {

    .content {
        padding: 0;
        margin: 30px 0 0 0;
    }

    .footnote {
        padding-top: 25px;
    }

    .no-padding-left {
        padding-left: 0;
    }
}
