.table > thead > tr > th {
    vertical-align: bottom;
}

.table > tbody > tr > td, .table > tfoot > tr > td {
    border-top: 0px solid @table-border-color;
}

.orgInnehav {
    color: #888888;
}

.innehav {
    color: #333333;
}

.change-history-table {
    > tbody {
        tr td:first-child {
            border-left: 1px solid @table-border-color;
        }
        tr td:last-child {
            border-right: 1px solid @table-border-color;
        }
        tr td {
            vertical-align: top !important;
        }
    }
}

#holding,
.holding {
    padding: 8px;
    .table-striped-inner {
        border-collapse: inherit;

        > tbody > tr:nth-child(2n+0) > td,
        > tbody > tr:nth-child(2n+0) > th {
            background-color: @well-bg;
        }

        > thead:first-of-type {
            > tr {
                border: 0px solid transparent;
                th {
                    background-color: inherit;
                }
            }
            > tr:first-child {
                border: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                th:first-child,
                td:first-child {
                    border: none;
                    border-top-left-radius: 0;
                }
                th:last-child
                td:last-child {
                    border: none;
                    border-top-right-radius: 0;
                }

            }

        }

        > tbody:first-of-type {

            tr td:first-child {
                border-left: 1px solid @table-border-color;
            }
            tr td:last-child {
                border-right: 1px solid @table-border-color;

            }

            > tr:first-child {
                td {
                    border-top: 1px solid @table-border-color;
                }
            }
        }

        > tbody:last-child {

            tr td:first-child, th:first-child {
                border-left: 1px solid @table-border-color;
            }
            tr td:last-child, th:last-child {
                border-right: 1px solid @table-border-color;

            }

            > tr:last-child td, th {
                border-bottom: 1px solid @table-border-color;
            }
        }
    }
}
