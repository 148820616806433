.branding-logo(@logo-url, @width) {
    display: inline;
    width: @width;
    height: 58px;
    padding: 0 20px;
    line-height: 61px;
    color: #ffffff;
    text-align: center;
    background: url(@logo-url) no-repeat 20px 50%;
}

#branded-banner-danica {
     .branding-logo('resources/img/branding/danica/danica.jpeg', 390px);
 }

#branded-banner-futur {
    .branding-logo('resources/img/branding/futur/futur.jpeg', 390px);
}

#branded-banner-movestic {
    .branding-logo('resources/img/branding/movestic/movestic.jpeg', 207px);
}

#branded-banner-skandialiv {
    .branding-logo('resources/img/branding/skandia/skandia.jpeg', 280px);
}

#branded-banner-lf {
    .branding-logo('resources/img/branding/lf/lf.jpeg', 280px);
}

#branded-banner-sebtryggliv {
    .branding-logo('resources/img/branding/seb/seb.jpeg', 154px);
}

#branded-banner-spp {
    .branding-logo('resources/img/branding/spp/spp.jpeg', 243px);
}

#branded-banner-folk {
    .branding-logo('resources/img/branding/folksam/folksam.jpeg', 257px);
}

#branded-banner-amf {
    .branding-logo('resources/img/branding/amf/amf.jpeg', 92px);
}
