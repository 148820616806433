@media screen and (max-width: @screen-xs) {
  ul#asset-page-tab-bar {
  width: 100%;
    li {
        width: 100%;
    }
    li:not(:first-child) a {
        border-radius: 0;
    }
  }
  .hidden-on-phone {
    display: none;
  }
}

@media (min-width: @screen-xs) {
    .show-only-on-phone {
        display: none;
    }
}

