#simulate-pension {
    .global-duration-addon {
        float: left;
        height: 28px;
    }

    .global-duration-text {
        width: 50px;
        padding: 4px;
        float: left;
        text-align: left;
        font-size: 12px;
    }

    .global-duration-slider {
        margin: 0;
        width: 124px;
        float: left;
    }

    .ngrs-range-slider {
        border-radius: 3px 0 0 3px;
    }

    .small-font {
        font-size: 12px;
    }

    .input-group-addon {
        width: 50px;
    }

    table.simulatePension {
        td {
            font-size: 12px;
        }

        td.input, th.input {
            padding: 4px;
            span.value-text {
                padding: 4px;
                display: inline-block;
            }
            background-color: #EDF4FC;
        }
        td.result, th.result {
            padding: 4px;
            span {
                padding: 4px;
                display: inline-block;
            }
            background-color: #E7FCDE;
        }
        td.active-pension-toggle {
            background-color: darken(#EDF4FC, 3%);
        }

        .dim {
            color: #999999;
            font-style: italic;
        }
        input {
            border: 1px solid #cccccc;
            border-radius: 3px;
        }
        input.sm {
            width: 50px;
        }

        input.md {
            width: 75px;

            @media (max-width: @screen-sm-max) {
                width: 60px;
            }


        }
        input.long {
            width: 150px;
        }

        .ngrs-range-slider {
            display: inline-table;
            width: 100px;
            margin: 0;
            height: @input-height-small;
        }
        input.input-group-addon {
            display: inline-table;
            background-color: white;
            float: left;
            margin-left: -4px;
        }

        .non-modifiable {
            text-align: center;
            padding: 4px;
            display: inline-block;
            vertical-align: top;
        }

        @media (max-width: @screen-md-max) {
            input.input-group-addon {
                display: inline-table;
                margin-top: 3px;
                margin-left: 0;
                border-left: 1px solid @table-border-color;
                width: 100px;
                .border-left-radius(3px);
            }
            .non-modifiable {
                text-align: center;
                padding: 8px;
                width: 100px;
                display: inline-block;
            }
        }
    }

    .general {
        label.control-label {
            text-align: left;
            font-weight: normal;
            font-size: 13px;
            padding-right: 2px;
        }
    }

    .header-btn {
        font-family: Helvetica, Arial, sans-serif;
    }

    @media (max-width: @screen-sm-max) {
        .general {
            .title {
                text-align: justify;
                padding-top: 10px;
            }
        }
    }

    table.simulatePension {
        border-collapse: inherit;

        > thead:first-of-type {
            > tr {
                border: 0px solid transparent;
            }
            > tr:first-child {
                border: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                th:first-child,
                td:first-child {
                    border: none;
                    border-top-left-radius: 0;
                }
                th:last-child
                td:last-child {
                    border: none;
                    border-top-right-radius: 0;
                }
            }
        }

        > tbody {

            tr {
                td {
                    border-top: 4px white solid;
                }

                td:first-child {
                    border-left: 1px solid @table-border-color;
                }

                td:last-child {
                    border-right: 1px solid @table-border-color;
                }
            }

            tr.active-pension, tr.ps-border-radius {
                td, th {
                    border-top: none;
                }
            }

            > tr:first-child {
                td:first-child {
                    border-left: 1px solid @table-border-color;
                    border-top-left-radius: (@panel-border-radius - 1);
                }
                td:last-child {
                    border-right: 1px solid @table-border-color;
                    border-top-right-radius: (@panel-border-radius - 1);
                }
                td {
                    border-top: 1px solid @table-border-color;
                }
            }

            > tr:last-child {

                td {
                    border-bottom: 1px solid @table-border-color;
                }

                td:first-child,
                th:first-child {
                    border-bottom-left-radius: (@panel-border-radius - 1);
                }
                td:last-child,
                th:last-child {
                    border-bottom-right-radius: (@panel-border-radius - 1);
                }

            }

            .ps-border-radius {
                td {
                    padding: 0;
                }
                height: (@panel-border-radius);
            }
        }

        .active-pension {
            td, th {
                background-color: darken(#EDF4FC, 3%);
            }

            th {
                font-size: 12px;
                padding-left: 8px;
                padding-right: 8px;
            }

            td {
                padding-bottom: 10px;
            }
        }
    }

    .simulate-pension-buttons {
        float: right;
    }

    .legend-rangeslidefields {
        font-size: 14px;
    }

    .text-color {
        color: @text-color;
    }
}

.save-simulation-margins {
    margin-bottom: 35px;
    margin-top: 5px;
}
