#loadingOverlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  opacity: 0.7;
  background: #fff;
}

#loading {
  width: 50%;
  height: 57px;
  position: absolute;
  top: 50%;
  left: 25%;
  margin: -28px 0 0 -25px;
}
