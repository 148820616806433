.table > tbody > tr > td {
    vertical-align: middle;
}

.input-mini {
  width: 50px;
}

.input-midi {
    width: 70px;
}

.form-control {
  height: 28px;
  display: inherit;;
}

.page-actions {
  float: right;
}

.imagebutton {
  background:none;
  border:none;
  box-shadow:none;
  width: 26px;
  height: 26px;
    padding: 0;
}

.hidden-link {
    color: #000000;
}

.invalid-fund {
    td {
        background-color: #f2dede;
    }
}

#modifieraModelPortfolios {
    margin: 0 15px;
}

.padding-10 {
    .padding(10px);
}

.mm-fund-choice-tab {
    .margin-top-20();
    padding-left: 0;
    padding-right: 0;

    ul.nav-tabs {

        > li {
            width: 50%;

            > a {

                h2 {
                    margin: 5px 0 5px 0;
                }

                .fund-choice-info {
                    margin: 0;
                }

            }
        }

        > li.active {
            a {
                border-bottom-color: #FFF;
            }
        }

        .min-height-105 {
            min-height: 105px;
        }

        @media (max-width: @screen-sm-min) {
            > li {
                width: 100%;
            }

            > li:not(:first-of-type) a {
                border-radius: 0;
            }
        }
    }

    .tab-content {
        border-radius: 0 0 @panel-border-radius @panel-border-radius;
    }
}

.red-border {
    border: 3px solid red;
}

