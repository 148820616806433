.ps-links {
    dd {
        padding-left: 10px;
        margin-bottom: 3px;
    }
}

label.ps-contact {
    font-weight: bold;
    margin: 5px 0;
}

.ps-contact-indent {
    padding-left: 20px;
}
