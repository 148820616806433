@import "variables.less";
@import "functions.less";
@import "fonts.less";

@button-default:                     #DDDDDD;
@button-default-border:              darken(@button-default, 10%);

@brand-primary:                      #004478;
@brand-success:                      #13741b;

@brand-info:                         #0084ff;
@brand-warning:                      #ffc242;
@brand-danger:                       #ff2c00;

@text-success:                       @brand-success;
@state-success-text:                 @brand-success;
@text-warning:                       @brand-warning;

@font-family-sans-serif:             Helvetica, Arial, sans-serif;
@navbar-default-color:               #fff;
@navbar-default-bg:                  #004478;
@navbar-default-border:              #003157;
@navbar-border-radius:               0;

// Navbar links
@navbar-default-link-color:          #fff;
@navbar-default-link-hover-color:    #eee;
@navbar-default-link-hover-bg:       transparent;
@navbar-default-link-active-color:   #fff;
@navbar-default-link-active-bg:      @brand-info;
@navbar-default-link-disabled-color: #ccc;
@navbar-default-link-disabled-bg:    transparent;

// Navbar brand label
@navbar-default-brand-color:         @navbar-default-link-color;
@navbar-default-brand-hover-color:   darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg:      transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:     #ddd;
@navbar-default-toggle-icon-bar-bg:  #FFF;
@navbar-default-toggle-border-color: #ddd;

//** Global textual link color.
@link-color:                         #0081FF;
//** Link hover color set via `darken()` function.
@link-hover-color:                   darken(@link-color, 15%);

//Other
@input-group-addon-bg:               #fff;
@table-bg-accent:                    #fff;

/*
* Maxm specific variables
*/
@maxm-body-bg-color:                       #ececec;
@maxm-container-bg-color:                  #ffffff;

@maxm-submenu-links-bg-color:              #f5f5f5;
@maxm-submenu-links-text-color:            #262626;
@maxm-submenu-links-hover-bg-color:        #efefef;

@maxm-panel-border-color:                  #ddd;
@maxm-panel-body-bg-color:                 #f5f5f5;
@maxm-panel-info-border-color:             #add8e6;
@maxm-panel-info-body-bg-color:            #DEEBFF;
@maxm-panel-warning-body-bg-color:         #faebcc;

@maxm-well-info-bg-color:                  #f5f5f5;
@maxm-well-success-bg-color:               #dff0d8;

@maxm-fund-order-arrow-color:              #19A025;
@maxm-fund-order-arrow-size:               96px;

@maxm-footnote-width:                      25px;

@maxm-tabs-color:                          #292929;
@maxm-risk-text-color:                     #808080;
@maxm-button-danger-bg-color:              #FF0000;
@maxm-text-danger-color:                   #FF0000;
@maxm-white-row-table-bg-color:            #fff;
@maxm-bootstrap-overlay-bg-color:          #fff;
@maxm-inactive-number-color:               #BBB;
@maxm-tab-content-striped-odd-bg-color:    #FFF;
@maxm-nav-tabs-link-bg-color:              #fff;
@maxm-mm-page-tabs-bottom-border-bg-color: #fff;
@maxm-ajax-loader-bg:                      #fff;
@maxm-alert-text-color:                    #000;
@maxm-expand-icon-color:                   #00568A;
@maxm-expand-icon-text-color:              #000;
@maxm-filled-star-color:                   orange;
@maxm-empty-star-color:                    lightgrey;


.librebaskerville {
  font-family: 'Libre Baskerville', serif;
}

.helvetica {
  font-family: 'Helvetica', sans-serif;
}

.ul-less-padding {
  padding-left: 20px;
}

.margin-top-30 {
  .top-margin(30px);
}

.margin-top-20 {
  .top-margin(20px);
}


.error {
  color: @brand-danger;
}

.btn-danger {
  background-color: @maxm-button-danger-bg-color;
}

.text-danger {
  color: @maxm-text-danger-color;
}

.green {
  color: @brand-success;
}

.primary {
  color: @brand-primary;
}

/* Used for debugging */
.red-border {
  border: 1px solid red;
}

.alert-warning, .alert-info, .alert-success, .alert-danger {
  color: @maxm-alert-text-color;
  border-color: transparent;
}

.text-white {
  color: @maxm-ajax-loader-bg;
}

.glyphicon-star, .fa-star {
  color: @maxm-filled-star-color;
}

.glyphicon-star-empty, .fa-star-empty {
  color: @maxm-empty-star-color;
}

.button-variant(@color; @background; @border) {
  color: @color;
  background-color: @background;
  border-color: @border;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color;
    background-color: lighten(@background, 10%);
    border-color: lighten(@border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @background;
      border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

ul.nav-tabs {
  > li.active {
    > a {
      background-color: @maxm-nav-tabs-link-bg-color;
    }
  }
}


