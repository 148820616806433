
.fund_history_table {
  thead {
    tr {
      th {
        border-top:0;
        border-bottom:0;
      }
      td {
        border-top:0;
        border-bottom:0;
      }
      td.underlined {
        border-bottom:1px solid;
      }
      td.center {
        text-align:center;
      }
    }
  }
}

@media print {
  .fund_history_table {
    a[href]:after {
      content:none;
    }
  }
}
