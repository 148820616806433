
.risk-group {

    text-align: center;

    .risk-value {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        padding: 5px 10px;
        color: black;
        background-color: white;
        border: 1px solid #c4c4c4;
    }

    .risk-value:first-child {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
    }

    .risk-value:last-child {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
    }

    .risk-value.active {
        color: black;
        background-color: #AAD5FF;
        border: 1px solid #004478;
    }

}
