.insurance-widget {
    margin-bottom: 20px;

    hr {
        margin: 10px 0 10px 0;
    }

    table.insurances {
        > tbody {

            > tr.insurance-row.collapsed > td {
                background-color: #fff;
            }

            > tr.insurance-row:hover > td,
            > tr.insurance-row.expanded > td {
                background-color: #fff;
                cursor: pointer;
            }
        }

        > tfoot tr {
            background-color: #fff;
        }

        .insurance {
            border: 0;

            > tr {

                td:first-child {
                    border-left: 1px solid @table-border-color;
                }
                td:last-child {
                    border-right: 1px solid @table-border-color;
                }
            }

            > tr {
                background-color: #fff;
            }

            .holding {
                padding: 0;
                border-top: 0;

                table > tbody {
                    td, th {
                        border-width: 0;
                    }
                }

                > div {
                    border-top: 0px;
                }

                .btn {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
