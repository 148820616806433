@import "node_modules/bootstrap/less/mixins.less";
@import "node_modules/bootstrap/less/variables.less";
@import "node_modules/font-awesome/less/variables.less";

@import "node_modules/@mm/gui-styling/src/less/mm-external-theme.less";
@import "node_modules/@mm/gui-styling/src/less/variables.less";
@import "node_modules/@mm/gui-styling/src/less/functions.less";


/* Components below */
@import "src/partial/home/home.less";
@import "src/fondbyte/fondbyte.less";
@import "src/partial/fundInfo/fundinfo.less";
@import "src/directive/loading/loading.less";
@import "src/directive/ps-language-selector/ps-language-selector.less";
@import "src/directive/ps-risk-bar/ps-risk-bar.less";
@import "src/directive/ps-alert/ps-alert.less";
@import "src/directive/ps-model-portfolios/ps-model-portfolios.less";
@import "src/directive/ps-generic-model-portfolios/ps-generic-model-portfolios.less";
@import "src/partial/assets/assets.less";
@import "src/partial/portfolios/portfolios.less";
@import "src/partial/simulatePension/simulatePension.less";
@import "src/partial/bssimulation/bssimulation.less";
@import "src/partial/support/support.less";
@import "src/partial/documents/documents.less";
@import "src/modalVersions/modalVersions.less";
@import "src/momentModalDetails/momentModalDetails.less";
@import "src/maxfonder/maxfonder.less";
@import "src/introfi/introfi.less";
@import "src/profilePage/profilePage.less";
@import "src/custom-slider.less";
@import "src/directive/ps-customer-offer-modal/ps-customer-offer-modal.less";
/* Component above */

html {
    font-size: revert;
}

@font-face {
    font-family: "RecifeDisplay";
    src: url("/ps/resources/font/RecifeDisplayWeb-Book.woff2") format("woff2"), url("/ps/resources/font/RecifeDisplayWeb-Book.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

h1,h2,h3,h4,h5,h6 {
    font-family: "RecifeDisplay", serif;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #001439;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color: #001439;
    color: #fff;
}

.navbar-default{
    background-color: #001439;
    .dropdown-toggle {
        background-color: #001439;
    }
}

.animate-if {
}

.animate-if.ng-enter, .animate-if.ng-leave {
    transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
    opacity:0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
    opacity:1;
}

.mm-message {
    margin: 1em;
}

.mm-expand-icon {
    color: @maxm-expand-icon-color;
    margin-right: 4px;
}

.mm-expand-text {
    color: @maxm-expand-icon-text-color;
}

/* Fix problem that the padding of the original bootstrap class ".btn" is refined after classes ".btn-sm" in gui-resources/external.css */
.btn {
    padding: 6px 12px;
}

.btn-sm {
    padding: 5px 10px;
}

.btn-xs {
    padding: 1px 5px;
}

#detailsFrame {
    background: #ffffff url(./resources/img/ajax-loader.gif) no-repeat fixed center;
}

.mm-modal-header {
    max-height: 20px;
    padding: 4px;
    border: none;
}

.mm-modal-close {
    cursor: pointer;
    color: #CCC;
    margin-right: 5px;
    margin-bottom: 0;
}

.mm-logo-size {
    height: 34px;
    width: 147px
}

.unreadMessagesPositioning{
    position: absolute;
    right:0;
    top:12px;
}

span.unreadMessages {
    background: @maxm-alert-red;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em;
    font-size: 10px;
}


