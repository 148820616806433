.top-margin(@px-margin) {
  margin-top: @px-margin;
}

.bottom-margin(@px-margin) {
  margin-bottom: @px-margin;
}

.padding(@px-padding) {
  padding: @px-padding;
}
