

.portfolios {
    margin: 0 -15px;

    ul.nav-tabs {

        > li {
            width: 16.6%;
        }

        > li.last {
            width: 17%;
        }

        > li > a {

            padding: 10px 5px;

            h3 {
                font-size: 18px;
                font-weight: normal;
            }

            h4 {
                margin-bottom: 0;
                margin-top: 5px;
            }

            .riskvilja {
                color: black;
                font-size: 12px;
            }

            @media (max-width: @screen-xs-max) {
                min-height: 157px;
                .number {
                    width: 75%;
                }
                h3 {
                    font-size: 16px;
                }
            }

            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                min-height: 96px;
            }

        }
    }
}

.portfolio-content.tab-content {
    border-top: none;

    h2 {
        padding-left: 10px;

        @media (min-width: @screen-sm-min) {
            display: none;
        }
    }

    .portfolio-column  {
        padding: 0 15px;
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
        .right-padding-half-small-medium {
            padding-right: 7.5px;
        }

        .left-padding-half-small-medium {
            padding-left: 7.5px;
        }

        .left-bottom-padding-half-small-medium {
            padding-left: 7.5px;
            padding-bottom: 70px;
        }
    }

    @media (min-width: @screen-lg-min) {
        .right-padding-half-large {
            padding-right: 7.5px;
        }

        .left-padding-half-large {
            padding-left: 7.5px;
        }
    }

    .intro {
        padding: 10px 10px 0 10px;
    }

    .portfolio-section-header {
        color: @brand-primary;
        font-size: 16px;
        margin: 25px 0 5px 0;
    }

    .portfolio-section {
        background-color: #f5f5f5;
        padding: 10px 10px 10px 10px;
        border: 1px solid @table-border-color;
        border-radius: (@panel-border-radius - 1);

        table.noHeader {
            margin-top: 10px;
        }

        table {
            margin-bottom: 0;
        }

        a.btn {
            margin-top: 10px;
        }

        .risk-group {
            margin-top: 15px;
        }

        div.alert {
            margin-top: 10px;
        }

    }

    .portfolio-section.documents {
        margin-top: 10px;
        padding: 15px;
    }

    .highcharts-container {
        margin: auto;
    }

}

@media (min-width: @screen-sm-min) {
    .portfolios ul.nav-tabs > li > a {
        min-height: 75px;
    }
}


@media screen and (max-width: @screen-xs) {
    ul#model-portfolio-page-tab-bar {
        width: 100%;
        li {
            width: 100%;
        }
        li:not(:first-child) a {
            border-radius: 0;
        }
        .number {
            font-size: 24px;
            display: inline;
            float: left;
            line-height: inherit;
            margin-top: -6px;
            width: auto;
        }

        h3, h4 {
            display: inline;
        }
    }

    .portfolios ul.nav-tabs > li > a {
        min-height: 0;
    }
}


.number-on-modelportfolios {
    .librebaskerville();
    font-size: 55px;
    display: inline-block;
    margin-right: 5px;
    line-height: 65px;
}

.inactive {
    .number-on-modelportfolios {
        color: @maxm-inactive-number-color;
    }
}
