.btn {
    border-radius: 32px;
    border: 1px solid #777777;
}
a {
    btn {
        color: #f5f5f5;
    }
}
.welcome {
    background-color: #001439;
    color: #f5f5f5;
    h2 {
        font-family: 'RecifeDisplay','Libre Baskerville', serif;
        font-size: 25px;
        color: #f5f5f5;
        padding: 15px 0px 15px 0px;
    }
    .col {
        padding: 15px 15px 15px 15px;
    }
    .intro {
        font-family: Helvetica, Arial, sans-serif;
        font-size: 16px;
        text-align: center;
        padding-bottom: 40px;
    }

}
.brokerinfo {
    background-color: #000000;
    color: #ada9a9;
    h4 {
        color: #f5f5f5;
    }
}
.launchpane {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    text-align: center;
    color: #1A1A1A;
    padding: 100px 50px 50px 50px;
    h2 {
        font-family: 'RecifeDisplay','Libre Baskerville', serif;
        font-size: 25px;
        color: #1A1A1A;
    }
    a:hover {
        color: #004478;
        .btn {
            border: 1px solid #004478;
        }
    }
    .btn {
        border-radius: 32px;
        border: 1px solid #777777;
        color: #1A1A1A;
    }
    .extrapadding{
        padding: 20px 0px 20px 0px;
    }
    p {
        padding: 5px 0px 5px 0px;
    }
}
//.content {
//    margin-top: 0px;
//}
//.navbar {
//    margin-bottom: 0px;
//}
//.introfi-banner{
//    background-color:#F7F7F7;
//    height: 70px;
//}
//.welcome-image{
//    background-image: url("/ps/resources/img/MM_neg.svg");
//}
