.arrow-row {
    top: -40px;
    left: 0;
}

.order-arrow-down-left {
    left: 5%;
    font-size: @maxm-fund-order-arrow-size;
    color: @maxm-fund-order-arrow-color;

    @media (max-width: @screen-sm-min) {
        left: 46%;
    }
}

.order-arrow-down-right {
    left: 84%;
    font-size: @maxm-fund-order-arrow-size;
    color: @maxm-fund-order-arrow-color;

    @media (max-width: @screen-sm-min) {
        display: none;
    }

    @media (max-width: @screen-md-min) and (min-width: @screen-sm-min) {
        left: 78%;
    }

    @media (max-width: @screen-lg-min) and (min-width: @screen-md-min) {
        left: 82%;
    }
}

.well > .radio:first-child {
    margin-top: 0;
}
